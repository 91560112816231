/* lexend-regular - latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  src: local('Lexend'),
       url('./Lexend-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./Lexend-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('./Lexend-Regular.ttf') format('ttf'); /* Safari, Android, iOS */
}
/* lexend-light - latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 200;
  src: local('Lexend'),
       url('./Lexend-Light.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./Lexend-Light.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('./Lexend-Light.ttf') format('ttf'); /* Safari, Android, iOS */
}
/* lexend-bold - latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  src: local('Lexend'),
  url('./Lexend-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./Lexend-Bold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url('./Lexend-Bold.ttf') format('ttf'); /* Safari, Android, iOS */
}
