.sim_name {
    width: 100%;
    float: left;
}

.listItemText {
    text-align: left;
    pointer-events: none;
}

.listItemText p {
    font-size: 14px;
    margin-bottom: 0px;
    pointer-events: none;
}

.listItemText span {
    font-size: 14px;
    pointer-events: none;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8 {
    border-left: 1px solid rgb(94, 94, 94);
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.tacticRow {
    border-top: 1px solid rgb(94, 94, 94);
    ;
    color: #2196F3
}

#simTitle {
    margin-top: 0px;
    margin-bottom: -20px
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
    font-size: 14px;
    padding-bottom: 2.5px;
    padding-left: 5px
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    font-size: 13px
}

.simStep {
    background: #303030;
    border-radius: 3px;
    margin-bottom: 15px;
    min-width: 384px;
}

.MuiPaper-root.MuiPaper-elevation0.MuiStepper-root.MuiStepper-horizontal {
    background: #303030;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
}

#trainingStepper .MuiPaper-root.MuiPaper-elevation0.MuiStepper-root.MuiStepper-vertical {
    background: #303030;
}

.MuiTypography-root.MuiPaper-root.MuiPaper-elevation6.MuiSnackbarContent-root.MuiTypography-body2 {
    width: calc(100% - 50px);
    background: #FFFF99
}

#snackBarWarning {
    float: left;
    margin-right: 5px
}

#message-id a {
    margin-top: -10px;
    float: right;
    margin-top: 5px
}

code {
    color: #2196F3
}