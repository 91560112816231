.MuiDataGrid-colCellWrapper > .MuiDataGrid-colCell:nth-last-child(2) > .MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-colCellNumeric > .MuiDataGrid-colCell-draggable {
  flex-direction: row-reverse;
}

.MuiDataGrid-root .MuiDataGrid-colCell-draggable > .MuiDataGrid-menuIcon {
  visibility: visible;
}